import { createApp } from 'vue';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
  .use(store)
  .use(router)
  .use(VueGtag, {
    config: { id: 'G-YPXR8031LN' },
  })
  .mount('#app');
