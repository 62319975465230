import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('../components/error/Error404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // eslint-disable-next-line consistent-return
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        offset: { x: 0, y: 120 },
      };
    }
  },
});

export default router;
