<template>
    <transition name="fade">
        <div v-if="show" class="cookies">
            Używamy plików cookies (tzw. „ciasteczka”) w&nbsp;celu optymalizacji korzystania
          ze stron internetowych, tworzenia statystyk. Możesz wyłączyć ten mechanizm
          w&nbsp;dowolnym momencie w&nbsp;ustawieniach przeglądarki.
            <button type="button" @click="hide">Zamknij</button>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'CookieComponent',
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (!localStorage.getItem('use-cookies')) {
        this.show = true;
      }
    },
    hide() {
      localStorage.setItem('use-cookies', 'true');
      this.show = false;
    },
  },
};
</script>
