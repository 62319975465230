<template>
  <router-view/>
  <cookie-component></cookie-component>
</template>

<script>
import CookieComponent from '@/components/CookieComponent.vue';

export default {
  name: 'App',
  components: {
    CookieComponent,
  },
};
</script>

<style lang="scss">
  body {
    font-family: 'HelveticaNeueLT Pro', arial sans-serif;
  }
  @import "~@/styles/app";
</style>
